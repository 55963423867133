import { AsyncStorage } from 'vuex-persist';
import { Plugins } from '@capacitor/core';
import VuexPersistence from 'vuex-persist';
import { createStore } from 'vuex';
import { territorios } from '@/modules/cyanRegions';

const { Storage } = Plugins;

interface Nivel {
	nivel: string;
	codigo: string;
	nombre: string;
	padre: string;
}

interface PartyListItem {
	id: number;
	nombre: string;
	telefono: string;
	email: string;
	nivel: string;
}

interface PartyListPayload {
	codigo: string;
	lista: PartyList | NotOK;
}

interface NavigationParameters {
	title: string;
	backRoute: string;
	nav1: string;
	nav2: string;
	ambito: string;
	burst: boolean;
	isForm: boolean;
}

interface PartyList {
	ok: true;
	organicos: PartyListItem[];
	coordinaciones: PartyListItem[];
	nivel: Nivel;
	tieneCargos: boolean;
	tieneOrganicos: boolean;
	tieneCoordinaciones: boolean;
}

interface NotOK {
	ok: false;
	reason: string;
	[k: string]: any;

}

interface PartyListStorage {
	[codigo: string]: PartyList | NotOK;
}


const AdaptedStorage: AsyncStorage = {
	length: function () { return Storage.keys().then((x) => x.keys.length) },
	key: function (n: number) {
		return Storage.keys().then((x) => x.keys.length <= n ? '' : x.keys[n])
	},
	getItem: function (key: string) { return Storage.get({ key }).then((x) => x.value ? JSON.parse(x.value) : null) },
	setItem: function (key: string, value) {
		return Storage.set({ key, value: JSON.stringify(value) }).then(() => value)
	},
	removeItem: function (key: string) { return Storage.remove({ key }) },
	clear: Storage.clear,
}

const vuexLocal = new VuexPersistence({
	storage: AdaptedStorage,
	asyncStorage: true
});

const store = createStore({
	state() {
		return {
			lightboxVisible: false,
			lightboxImages: [],
			isLoggedIn: false,
			lastLoggedIn: '',
			rememberEnabled: false,
			userData: {},
			partyLists: {} as PartyListStorage,
			votantes: [],
			votantesCargados: false,
			smsRequest: { value: '' },
			routeParams: {},
			bootstrap: {},
			allowedCentrosJuntas: {},
			movilizados: {},
			incidencias: {},
			conteo: {},
			actas: {},
			dirJunta: {},
			dirCentro: {},
			dirStreamlined: {},
			nav: {
				title: '',
				backRoute: '',
				nav1: '',
				nav2: '',
				ambito: ''
			} as NavigationParameters,
			currentTabDirectorio: '',
			imageQueue: {},
			currentIQSerial: 0
		}
	},
	mutations: {
		userLogin(state: any, payload) {
			state.isLoggedIn = true;

			// Preservar CSRF (se activa desde la petición)
			const prevCsrf = state.userData.csrf;

			state.userData = prevCsrf ?
				{ ...state.userData, csrf: prevCsrf } : { ...payload };

			state.smsRequest = { value: '' };

			if (payload.permanent) {
				state.rememberEnabled = true;
				state.lastLoggedIn = payload.name;
			} else {
				state.rememberEnabled = false;
				state.lastLoggedIn = '';
			}

			if (payload.allowedCentrosJuntas) {
				state.allowedCentrosJuntas[payload.nivel] = payload.allowedCentrosJuntas;
			}
		},
		userUpdateData(state: any, payload) {
			if (!state.isLoggedIn) return; // solo si el usuario tiene su sesión iniciada

			state.userData = { ...state.userData, ...payload};

			if (payload.allowedCentrosJuntas) {
				state.allowedCentrosJuntas[payload.nivel] = payload.allowedCentrosJuntas;
			}
		},
		requestedSMSCode(state: any, payload: string) {
			state.smsRequest = { value: payload };
		},
		storeTabDirectorio(state: any, payload: string) {
			state.currentTabDirectorio = payload;
		},
		setLightboxVisible(state: any, payload: boolean) {
			state.lightboxVisible = payload;
		},
		setLightboxImages(state: any, payload: boolean) {
			state.lightboxImages = payload;
		},
		setUserCsrf(state: any, payload: string) {
			state.userData.csrf = payload;
		},
		storeRouteParam(state: any, payload: any) {
			state.routeParams[payload.key] = payload.value;
		},
		userLogout(state: any) {
			state.isLoggedIn = false;
			state.userData = {};
			state.allowedCentrosJuntas = {};
			state.partyLists = {};
			state.votantes = [];
			state.movilizados = {};
			state.bootstrap = {};
			state.dirJunta = {};
			state.dirCentro = {};
			state.incidencias = {};
			state.conteo = {};
			state.actas = {};
			state.votantesCargados = false;
		},
		storePartyList(state: any, payload: PartyListPayload) {

			state.partyLists[payload.codigo] = payload.lista;
		},
		storeACJ(state: any, payload: any) {
			state.allowedCentrosJuntas[payload.nivel] = payload;
		},
		storeMovilizados(state: any, payload: any) {
			state.movilizados[payload.id_centro] = payload;
		},
		storeBootstrap(state: any, payload: any) {
			state.bootstrap[payload.junta.id] = payload;
		},
		storeIncidencias(state: any, payload: any) {
			state.incidencias[payload.junta_id] = payload;
		},
		storeActas(state: any, payload: any) {
			state.actas[payload.id_junta] = payload.actas;
		},
		storeActa(state: any, payload: any) {
			if (typeof state.actas[payload.id_junta] === 'undefined') state.actas[payload.id_junta] = {};

			state.actas[payload.id_junta][payload.id_convocatoria] = payload.acta;
		},
		storeConteo(state: any, payload: any) {
			if (typeof state.conteo[payload.id_junta] === 'undefined')
				state.conteo[payload.id_junta] = {};

			state.conteo[payload.id_junta][payload.id_convocatoria] = payload;
		},
		storeVotoConteo(state: any, payload: any) {

			if (typeof state.conteo[payload.id_junta] === 'undefined')
				state.conteo[payload.id_junta] = {};
			if (typeof state.conteo[payload.id_junta][payload.id_convocatoria] === 'undefined')
				state.conteo[payload.id_junta][payload.id_convocatoria] = { estado: {} };

			state.conteo[payload.id_junta][payload.id_convocatoria].estado[payload.id_partido] = payload.votos;
		},
		storeDirectorioStreamlined(state: any, payload: any) {
			const ic = (state.userData && state.userData.centro && state.userData.centro.id) ? state.userData.centro.id : -1;
			state.dirStreamlined[ic] = payload;

		},
		storeAsistenciaStreamlined(state: any, payload: any) {
			const ic = (state.userData && state.userData.centro && state.userData.centro.id) ? state.userData.centro.id : -1;
			if (!state.dirStreamlined[ic] 
				|| !state.dirStreamlined[ic].value
				|| !state.dirStreamlined[ic].value.datosCargos
				|| !state.dirStreamlined[ic].value.datosCargos[payload.id]) return;

			state.dirStreamlined[ic].value.datosCargos[payload.id].asistio = payload.asistio;

		},
		storeIncidencia(state: any, payload: any) {

			if (typeof state.incidencias[payload.junta_id] === 'undefined') {
				/* eslint-disable @typescript-eslint/camelcase */
				state.incidencias[payload.junta_id] = { ok: true, junta_id: payload.junta_id, count: 0, incidencias: [] };
				/* eslint-enable @typescript-eslint/camelcase */
			}

			const inc = state.incidencias[payload.junta_id];

			for (const idx in inc.incidencias) {
				const i = inc.incidencias[idx];
				if (i.id == payload.id) {
					inc.incidencias[idx] = payload;
					return;
				}
			}

			inc.count++;
			inc.incidencias.push(payload);

		},
		storeDirCentro(state: any, payload: any) {
			state.dirCentro[payload.id_centro] = payload;
		},
		storeAsistencia(state: any, payload: any) {
			if (payload.id_centro
				&& typeof state.dirCentro[payload.id_centro] !== 'undefined'
				&& typeof state.dirCentro[payload.id_centro].datosCargos[payload.id_cargo] !== 'undefined')
				state.dirCentro[payload.id_centro].datosCargos[payload.id_cargo].asistio = payload.asistio;
			

			if (payload.id_junta &&
				typeof state.dirJunta[payload.id_junta] !== 'undefined' &&
				typeof state.dirJunta[payload.id_junta].datosCargos[payload.id_cargo] !== 'undefined') 
				state.dirJunta[payload.id_junta].datosCargos[payload.id_cargo].asistio = payload.asistio;
			},
		storeDirJunta(state: any, payload: any) {
			state.dirJunta[payload.id_junta] = payload;
		},
		storeVotantes(state: any, payload: any) {

			state.votantes = payload;
			state.votantesCargados = true;
		},
		addVotante(state: any, payload: any) {

			state.votantes.push(payload);
		},
		storeNav(state: any, payload: NavigationParameters) {
			state.nav = payload;
		},
		patchUserData(state: any, payload: any) {
			state.userData[payload.key] = payload.value;
		},
		queueImageActa(state: any, payload: any) {
			const j = payload.junta;
			const c = payload.convocatoria;
			const imagen = payload.imagen;

			if (typeof state.imageQueue[j] === 'undefined')
				state.imageQueue[j] = {};
			if (typeof state.imageQueue[j][c] === 'undefined')
				state.imageQueue[j][c] = [];

			const serial = ++state.currentIQSerial;

			state.imageQueue[j][c].push({
				serial,
				junta: j,
				convocatoria: c,
				imagen
			});
		},
		dequeueImageActa(state: any, payload: any) {
			const j = payload.junta;
			const c = payload.convocatoria;

			if (typeof state.imageQueue[j] === 'undefined')
				return;
			if (typeof state.imageQueue[j][c] === 'undefined')
				return;
			
			const nq = [];

			for (let n = 0; n != state.imageQueue[j][c].length; n++)
				if (state.imageQueue[j][c][n].serial != payload.serial) nq.push(state.imageQueue[j][c][n]);

			state.imageQueue[j][c] = nq;
		},
		storeBocaUrna(state: any, payload: any) {

			if (!state.userData.bocaUrna)
				state.userData.bocaUrna = {};

			const bu = state.userData.bocaUrna;

			if (!bu[payload.convocatoria_id])
				bu[payload.convocatoria_id] = {};

			bu[payload.convocatoria_id][payload.partido_id] = payload.votos;
		}
	},
	getters: {
		directorioStreamlined(state) {
			const ic = (state.userData && state.userData.centro && state.userData.centro.id) ? state.userData.centro.id : -1;
			return state.dirStreamlined[ic] ?? false;

		},
		listaVotantes(state) {
			if (!state.votantes || !state.votantesCargados) return [];
			return state.votantes;
		},
		smsRequestUser(state) {
			return state.smsRequest.value;
		},
		userCsrf(state) {
			return state.userData.csrf;
		},
		userHasPermission: (state) => (perm: string) => {
			if (!state.userData || !state.userData.permisos) return false;
			return state.userData.permisos.includes(perm);
		},
		userNumPermissions(state) {
			if (!state.userData || !state.userData.permisos) return false;
			return state.userData.permisos.length;
		},
		nivel(state) {
			if (!state.userData || !state.userData.nivel) return '';
			return state.userData.nivel;
		},
		startUrl: (state, getters) => (data: any) => {
			const d = {
				depto: false,	// Nivel SV
				mpio: false,	// Nivel SVxx
				centro: false,	// Nivel SVxxxx y múltiples centros posibles
				junta: false,	// Nivel SVxxxx y múltiples juntas posibles
				target: false,  // Hoja final

				fail: '/home',	// Error de algún tipo

				hastaJunta: true, // Llegar hasta junta o quedarse en el centro
				targetCentroFull: false, // Si no es falsy y en el camino llegamos a un centro full, p'acá que vamos
				soloCentroFull: null, // Ignorar los centros no full. Si es nulo, se cambia a !hastaJunta

				...data
			};


			if (d.soloCentroFull === null)
				d.soloCentroFull = !d.hastaJunta;

			const n = getters.nivel;

			if (typeof (territorios as any)[n] === 'undefined') return d.fail;
			if (n.length == 2) return d.depto;
			if (n.length == 4) return d.mpio + '/' + n;

			// tiene que ser un municipio
			// tenemos info de ACJ?

			const acj = state.allowedCentrosJuntas[n];

			const urlCentro = d.centro + '/' + n; // Este debería ser el arranque en general, salvo que lo podamos saltar

			if (typeof acj === undefined) { // Fallback, que se las apañe la página de centro
				return urlCentro;
			}

			// Verificar centros posibles

			const centrosPosibles = d.soloCentroFull ? acj.centrosFull : acj.centros;

			if (centrosPosibles.length != 1) { 	// Si hay más de un centro posible, o si 
				// no hay ninguno (no debería pasar, pero...)
				return urlCentro;
			}

			// Un solo centro. Si es full y targetCentroFull, cortocircuitar
			// (Si es full, entonces tiene que haber un solo centro en centros y centrosFull)

			if (acj.centrosFull.length == 1 && d.targetCentroFull) {
				return d.targetCentroFull + '/' + n + '/' + centrosPosibles[0];
			}

			const targetBase = d.target + '/' + n + '/';

			// Un solo centro posible
			// Si estamos en modo centro, hemos ganado

			if (!d.hastaJunta) return targetBase + centrosPosibles[0];

			// Ver número de juntas asignadas al centro

			const juntasPosibles = acj.mapaCentrosJuntas[centrosPosibles[0]];

			if (juntasPosibles.length > 1) {
				return d.junta + '/' + n + '/' + centrosPosibles[0];
			} else {
				return targetBase + juntasPosibles[0];
			}

		},

		rutaPuntoCyan: (state, getters) => {
			return getters.startUrl({
				hastaJunta: false,
				depto: '/puntoPickDepto',
				mpio: '/puntoPickMpio',
				centro: '/puntoPickCentro',
				target: '/punto'
			});
		},
		rutaAgente: (state, getters) => {
			return getters.startUrl({
				hastaJunta: true,
				depto: '/agentePickDepto',
				mpio: '/agentePickMpio',
				centro: '/agentePickCentro',
				junta: '/agentePickJunta',
				target: '/agente'
			});
		},

		rutaDirectorio: (state, getters) => {
			return getters.startUrl({
				hastaJunta: true,
				depto: '/dirPickDepto',
				mpio: '/dirPickMpio',
				centro: '/dirPickCentro',
				junta: '/dirPickJunta',
				target: '/dirJunta',
				targetCentroFull: '/dirCentro'
			});
		},

	},
	actions: {},
	plugins: [vuexLocal.plugin]
})


export default store;