
import {
  IonApp,
  IonRouterOutlet,
  IonContent,
  IonTitle,
  IonToolbar,
  IonList,
  IonIcon,
  IonMenu,
  IonItem,
  menuController,
} from "@ionic/vue";

import {
  arrowBack,
  chevronBack,
  homeOutline,
  exitOutline,
  helpBuoyOutline,
  trophyOutline,
  megaphoneOutline,
} from "ionicons/icons";
import { defineComponent } from "vue";
import router from "@/router";
import store from "@/modules/adaptedStorage";
import UserData from "@/components/UserData.vue";
import logout from "@/modules/cyanLogout";

const AppComponent = defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonIcon,
    IonList,
    IonTitle,
    IonToolbar,
    IonMenu,
    UserData,
    IonItem,
  },
  setup() {
    return {
      arrowBack,
      chevronBack,
      homeOutline,
      exitOutline,
      helpBuoyOutline,
      trophyOutline,
      megaphoneOutline,
    };
  },
  computed: {
    esAzul() {
      return store.getters.userHasPermission('busl') && !store.getters.userHasPermission('jrsl') && !store.getters.userHasPermission('pcsl');
    },
    lightboxVisible() {
      return store.state.lightboxVisible;
    },
    lightboxImages() {
      return store.state.lightboxImages;
    },
  },
  methods: {
    menuGo(where: any) {
      menuController.close();
      router.push(where);
    },
    esconderLightbox() {
      store.commit("setLightboxVisible", false);
    },
    menuLogout() {
      menuController.close();
      logout();
    },
  },
});

export default AppComponent;
