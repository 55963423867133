import store from '@/modules/adaptedStorage';
import router from '@/router';
import { toastController, alertController } from '@ionic/vue';
import cyanRequest from './cyanRequest';

async function logout() {
	const alert = await alertController.create({
		header: 'Cerrar sesión',
		message: 'Confirme que desea cerrar su sesión.',
		buttons: [
			{ text: 'Cancelar', role: 'cancel' }, 
			{ text: 'Aceptar', handler: async function() {
				const toast = await toastController.create({
					message: "Sesión cerrada",
					duration: 3000
				});
				toast.present();
				cyanRequest('/logout', {
					silent: true,
					needsAuth: true
				});
				store.commit("userLogout");
				router.replace("/login");
				for (const i of [ '.__login-password', '.__login-DUI']) {
					for (const j of ['', ' input']) {
						const k = document.querySelector(i+j) as HTMLInputElement|null;
						if (k && typeof k.value !== 'undefined') {
							k.value = '';
						}
					}
				}
			} }]

	});
	alert.present();
}

export default logout;