interface ElementoMapaCyan {
	codigo: string;
	nombre: string;
	nombrePadre?: string;
}

const departamentos: Record<string, ElementoMapaCyan> = {
	SV01: { codigo: 'SV01', nombre: 'SAN SALVADOR' },
	SV02: { codigo: 'SV02', nombre: 'SANTA ANA' },
	SV03: { codigo: 'SV03', nombre: 'SAN MIGUEL' },
	SV04: { codigo: 'SV04', nombre: 'LA LIBERTAD' },
	SV05: { codigo: 'SV05', nombre: 'USULUTAN' },
	SV06: { codigo: 'SV06', nombre: 'SONSONATE' },
	SV07: { codigo: 'SV07', nombre: 'LA UNION' },
	SV08: { codigo: 'SV08', nombre: 'LA PAZ' },
	SV09: { codigo: 'SV09', nombre: 'CHALATENANGO' },
	SV10: { codigo: 'SV10', nombre: 'CUSCATLAN' },
	SV11: { codigo: 'SV11', nombre: 'AHUACHAPAN' },
	SV12: { codigo: 'SV12', nombre: 'MORAZAN' },
	SV13: { codigo: 'SV13', nombre: 'SAN VICENTE' },
	SV14: { codigo: 'SV14', nombre: 'CABAÑAS' },
};

const municipios: Record<string, ElementoMapaCyan> = {
	SV0101: { codigo: 'SV0101', nombre: 'SAN SALVADOR', nombrePadre: 'SAN SALVADOR' },
	SV0102: { codigo: 'SV0102', nombre: 'CIUDAD DELGADO', nombrePadre: 'SAN SALVADOR' },
	SV0103: { codigo: 'SV0103', nombre: 'MEJICANOS', nombrePadre: 'SAN SALVADOR' },
	SV0104: { codigo: 'SV0104', nombre: 'SOYAPANGO', nombrePadre: 'SAN SALVADOR' },
	SV0105: { codigo: 'SV0105', nombre: 'CUSCATANCINGO', nombrePadre: 'SAN SALVADOR' },
	SV0106: { codigo: 'SV0106', nombre: 'SAN MARCOS', nombrePadre: 'SAN SALVADOR' },
	SV0107: { codigo: 'SV0107', nombre: 'ILOPANGO', nombrePadre: 'SAN SALVADOR' },
	SV0108: { codigo: 'SV0108', nombre: 'NEJAPA', nombrePadre: 'SAN SALVADOR' },
	SV0109: { codigo: 'SV0109', nombre: 'APOPA', nombrePadre: 'SAN SALVADOR' },
	SV0110: { codigo: 'SV0110', nombre: 'SAN MARTIN', nombrePadre: 'SAN SALVADOR' },
	SV0111: { codigo: 'SV0111', nombre: 'PANCHIMALCO', nombrePadre: 'SAN SALVADOR' },
	SV0112: { codigo: 'SV0112', nombre: 'AGUILARES', nombrePadre: 'SAN SALVADOR' },
	SV0113: { codigo: 'SV0113', nombre: 'TONACATEPEQUE', nombrePadre: 'SAN SALVADOR' },
	SV0114: { codigo: 'SV0114', nombre: 'SANTO TOMAS', nombrePadre: 'SAN SALVADOR' },
	SV0115: { codigo: 'SV0115', nombre: 'SANTIAGO TEXACUANGOS', nombrePadre: 'SAN SALVADOR' },
	SV0116: { codigo: 'SV0116', nombre: 'EL PAISNAL', nombrePadre: 'SAN SALVADOR' },
	SV0117: { codigo: 'SV0117', nombre: 'GUAZAPA', nombrePadre: 'SAN SALVADOR' },
	SV0118: { codigo: 'SV0118', nombre: 'AYUTUXTEPEQUE', nombrePadre: 'SAN SALVADOR' },
	SV0119: { codigo: 'SV0119', nombre: 'ROSARIO DE MORA', nombrePadre: 'SAN SALVADOR' },
	SV0201: { codigo: 'SV0201', nombre: 'SANTA ANA', nombrePadre: 'SANTA ANA' },
	SV0202: { codigo: 'SV0202', nombre: 'CHALCHUAPA', nombrePadre: 'SANTA ANA' },
	SV0203: { codigo: 'SV0203', nombre: 'METAPAN', nombrePadre: 'SANTA ANA' },
	SV0204: { codigo: 'SV0204', nombre: 'COATEPEQUE', nombrePadre: 'SANTA ANA' },
	SV0205: { codigo: 'SV0205', nombre: 'EL CONGO', nombrePadre: 'SANTA ANA' },
	SV0206: { codigo: 'SV0206', nombre: 'TEXISTEPEQUE', nombrePadre: 'SANTA ANA' },
	SV0207: { codigo: 'SV0207', nombre: 'CANDELARIA DE LA FRONTERA', nombrePadre: 'SANTA ANA' },
	SV0208: { codigo: 'SV0208', nombre: 'SAN SEBASTIAN SALITRILLO', nombrePadre: 'SANTA ANA' },
	SV0209: { codigo: 'SV0209', nombre: 'SANTA ROSA GUACHIPILIN', nombrePadre: 'SANTA ANA' },
	SV0210: { codigo: 'SV0210', nombre: 'SANTIAGO DE LA FRONTERA', nombrePadre: 'SANTA ANA' },
	SV0211: { codigo: 'SV0211', nombre: 'EL PORVENIR', nombrePadre: 'SANTA ANA' },
	SV0212: { codigo: 'SV0212', nombre: 'MASAHUAT', nombrePadre: 'SANTA ANA' },
	SV0213: { codigo: 'SV0213', nombre: 'SAN ANTONIO PAJONAL', nombrePadre: 'SANTA ANA' },
	SV0301: { codigo: 'SV0301', nombre: 'SAN MIGUEL', nombrePadre: 'SAN MIGUEL' },
	SV0302: { codigo: 'SV0302', nombre: 'CHINAMECA', nombrePadre: 'SAN MIGUEL' },
	SV0303: { codigo: 'SV0303', nombre: 'EL TRANSITO', nombrePadre: 'SAN MIGUEL' },
	SV0304: { codigo: 'SV0304', nombre: 'CIUDAD BARRIOS', nombrePadre: 'SAN MIGUEL' },
	SV0305: { codigo: 'SV0305', nombre: 'CHIRILAGUA', nombrePadre: 'SAN MIGUEL' },
	SV0306: { codigo: 'SV0306', nombre: 'SESORI', nombrePadre: 'SAN MIGUEL' },
	SV0307: { codigo: 'SV0307', nombre: 'SAN RAFAEL ORIENTE', nombrePadre: 'SAN MIGUEL' },
	SV0308: { codigo: 'SV0308', nombre: 'MONCAGUA', nombrePadre: 'SAN MIGUEL' },
	SV0309: { codigo: 'SV0309', nombre: 'LOLOTIQUE', nombrePadre: 'SAN MIGUEL' },
	SV0310: { codigo: 'SV0310', nombre: 'SAN JORGE', nombrePadre: 'SAN MIGUEL' },
	SV0311: { codigo: 'SV0311', nombre: 'CHAPELTIQUE', nombrePadre: 'SAN MIGUEL' },
	SV0312: { codigo: 'SV0312', nombre: 'SAN GERARDO', nombrePadre: 'SAN MIGUEL' },
	SV0313: { codigo: 'SV0313', nombre: 'CAROLINA', nombrePadre: 'SAN MIGUEL' },
	SV0314: { codigo: 'SV0314', nombre: 'QUELEPA', nombrePadre: 'SAN MIGUEL' },
	SV0315: { codigo: 'SV0315', nombre: 'SAN LUIS DE LA REINA', nombrePadre: 'SAN MIGUEL' },
	SV0316: { codigo: 'SV0316', nombre: 'NUEVO EDEN DE SAN JUAN', nombrePadre: 'SAN MIGUEL' },
	SV0317: { codigo: 'SV0317', nombre: 'NUEVA GUADALUPE', nombrePadre: 'SAN MIGUEL' },
	SV0318: { codigo: 'SV0318', nombre: 'ULUAZAPA', nombrePadre: 'SAN MIGUEL' },
	SV0319: { codigo: 'SV0319', nombre: 'COMACARAN', nombrePadre: 'SAN MIGUEL' },
	SV0320: { codigo: 'SV0320', nombre: 'SAN ANTONIO DEL MOSCO', nombrePadre: 'SAN MIGUEL' },
	SV0401: { codigo: 'SV0401', nombre: 'SANTA TECLA', nombrePadre: 'LA LIBERTAD' },
	SV0402: { codigo: 'SV0402', nombre: 'QUEZALTEPEQUE', nombrePadre: 'LA LIBERTAD' },
	SV0403: { codigo: 'SV0403', nombre: 'CIUDAD ARCE', nombrePadre: 'LA LIBERTAD' },
	SV0404: { codigo: 'SV0404', nombre: 'SAN JUAN OPICO', nombrePadre: 'LA LIBERTAD' },
	SV0405: { codigo: 'SV0405', nombre: 'COLON', nombrePadre: 'LA LIBERTAD' },
	SV0406: { codigo: 'SV0406', nombre: 'LA LIBERTAD', nombrePadre: 'LA LIBERTAD' },
	SV0407: { codigo: 'SV0407', nombre: 'ANTIGUO CUSCATLAN', nombrePadre: 'LA LIBERTAD' },
	SV0408: { codigo: 'SV0408', nombre: 'COMASAGUA', nombrePadre: 'LA LIBERTAD' },
	SV0409: { codigo: 'SV0409', nombre: 'SAN PABLO TACACHICO', nombrePadre: 'LA LIBERTAD' },
	SV0410: { codigo: 'SV0410', nombre: 'JAYAQUE', nombrePadre: 'LA LIBERTAD' },
	SV0411: { codigo: 'SV0411', nombre: 'HUIZUCAR', nombrePadre: 'LA LIBERTAD' },
	SV0412: { codigo: 'SV0412', nombre: 'TEPECOYO', nombrePadre: 'LA LIBERTAD' },
	SV0413: { codigo: 'SV0413', nombre: 'TEOTEPEQUE', nombrePadre: 'LA LIBERTAD' },
	SV0414: { codigo: 'SV0414', nombre: 'CHILTIUPAN', nombrePadre: 'LA LIBERTAD' },
	SV0415: { codigo: 'SV0415', nombre: 'NUEVO CUSCATLAN', nombrePadre: 'LA LIBERTAD' },
	SV0416: { codigo: 'SV0416', nombre: 'TAMANIQUE', nombrePadre: 'LA LIBERTAD' },
	SV0417: { codigo: 'SV0417', nombre: 'SACACOYO', nombrePadre: 'LA LIBERTAD' },
	SV0418: { codigo: 'SV0418', nombre: 'SAN JOSE VILLANUEVA', nombrePadre: 'LA LIBERTAD' },
	SV0419: { codigo: 'SV0419', nombre: 'ZARAGOZA', nombrePadre: 'LA LIBERTAD' },
	SV0420: { codigo: 'SV0420', nombre: 'TALNIQUE', nombrePadre: 'LA LIBERTAD' },
	SV0421: { codigo: 'SV0421', nombre: 'SAN MATIAS', nombrePadre: 'LA LIBERTAD' },
	SV0422: { codigo: 'SV0422', nombre: 'JICALAPA', nombrePadre: 'LA LIBERTAD' },
	SV0501: { codigo: 'SV0501', nombre: 'USULUTAN', nombrePadre: 'USULUTAN' },
	SV0502: { codigo: 'SV0502', nombre: 'JIQUILISCO', nombrePadre: 'USULUTAN' },
	SV0503: { codigo: 'SV0503', nombre: 'BERLIN', nombrePadre: 'USULUTAN' },
	SV0504: { codigo: 'SV0504', nombre: 'SANTIAGO DE MARIA', nombrePadre: 'USULUTAN' },
	SV0505: { codigo: 'SV0505', nombre: 'JUCUAPA', nombrePadre: 'USULUTAN' },
	SV0506: { codigo: 'SV0506', nombre: 'SANTA ELENA', nombrePadre: 'USULUTAN' },
	SV0507: { codigo: 'SV0507', nombre: 'JUCUARAN', nombrePadre: 'USULUTAN' },
	SV0508: { codigo: 'SV0508', nombre: 'SAN AGUSTIN', nombrePadre: 'USULUTAN' },
	SV0509: { codigo: 'SV0509', nombre: 'OZATLAN', nombrePadre: 'USULUTAN' },
	SV0510: { codigo: 'SV0510', nombre: 'ESTANZUELAS', nombrePadre: 'USULUTAN' },
	SV0511: { codigo: 'SV0511', nombre: 'MERCEDES UMAÑA', nombrePadre: 'USULUTAN' },
	SV0512: { codigo: 'SV0512', nombre: 'ALEGRIA', nombrePadre: 'USULUTAN' },
	SV0513: { codigo: 'SV0513', nombre: 'CONCEPCION BATRES', nombrePadre: 'USULUTAN' },
	SV0514: { codigo: 'SV0514', nombre: 'SAN FRANCISCO JAVIER', nombrePadre: 'USULUTAN' },
	SV0515: { codigo: 'SV0515', nombre: 'PUERTO EL TRIUNFO', nombrePadre: 'USULUTAN' },
	SV0516: { codigo: 'SV0516', nombre: 'TECAPAN', nombrePadre: 'USULUTAN' },
	SV0517: { codigo: 'SV0517', nombre: 'SAN DIONISIO', nombrePadre: 'USULUTAN' },
	SV0518: { codigo: 'SV0518', nombre: 'EREGUAYQUIN', nombrePadre: 'USULUTAN' },
	SV0519: { codigo: 'SV0519', nombre: 'SANTA MARIA', nombrePadre: 'USULUTAN' },
	SV0520: { codigo: 'SV0520', nombre: 'NUEVA GRANADA', nombrePadre: 'USULUTAN' },
	SV0521: { codigo: 'SV0521', nombre: 'EL TRIUNFO', nombrePadre: 'USULUTAN' },
	SV0522: { codigo: 'SV0522', nombre: 'SAN BUENAVENTURA', nombrePadre: 'USULUTAN' },
	SV0523: { codigo: 'SV0523', nombre: 'CALIFORNIA', nombrePadre: 'USULUTAN' },
	SV0601: { codigo: 'SV0601', nombre: 'SONSONATE', nombrePadre: 'SONSONATE' },
	SV0602: { codigo: 'SV0602', nombre: 'IZALCO', nombrePadre: 'SONSONATE' },
	SV0603: { codigo: 'SV0603', nombre: 'ACAJUTLA', nombrePadre: 'SONSONATE' },
	SV0604: { codigo: 'SV0604', nombre: 'ARMENIA', nombrePadre: 'SONSONATE' },
	SV0605: { codigo: 'SV0605', nombre: 'NAHUIZALCO', nombrePadre: 'SONSONATE' },
	SV0606: { codigo: 'SV0606', nombre: 'JUAYUA', nombrePadre: 'SONSONATE' },
	SV0607: { codigo: 'SV0607', nombre: 'SAN JULIAN', nombrePadre: 'SONSONATE' },
	SV0608: { codigo: 'SV0608', nombre: 'SONZACATE', nombrePadre: 'SONSONATE' },
	SV0609: { codigo: 'SV0609', nombre: 'SAN ANTONIO DEL MONTE', nombrePadre: 'SONSONATE' },
	SV0610: { codigo: 'SV0610', nombre: 'NAHULINGO', nombrePadre: 'SONSONATE' },
	SV0611: { codigo: 'SV0611', nombre: 'CUISNAHUAT', nombrePadre: 'SONSONATE' },
	SV0612: { codigo: 'SV0612', nombre: 'SANTA CATARINA MASAHUAT', nombrePadre: 'SONSONATE' },
	SV0613: { codigo: 'SV0613', nombre: 'CALUCO', nombrePadre: 'SONSONATE' },
	SV0614: { codigo: 'SV0614', nombre: 'SANTA ISABEL ISHUATAN', nombrePadre: 'SONSONATE' },
	SV0615: { codigo: 'SV0615', nombre: 'SALCOATITAN', nombrePadre: 'SONSONATE' },
	SV0616: { codigo: 'SV0616', nombre: 'SANTO DOMINGO DE GUZMAN', nombrePadre: 'SONSONATE' },
	SV0701: { codigo: 'SV0701', nombre: 'LA UNION', nombrePadre: 'LA UNION' },
	SV0702: { codigo: 'SV0702', nombre: 'SANTA ROSA DE LIMA', nombrePadre: 'LA UNION' },
	SV0703: { codigo: 'SV0703', nombre: 'PASAQUINA', nombrePadre: 'LA UNION' },
	SV0704: { codigo: 'SV0704', nombre: 'SAN ALEJO', nombrePadre: 'LA UNION' },
	SV0705: { codigo: 'SV0705', nombre: 'ANAMOROS', nombrePadre: 'LA UNION' },
	SV0706: { codigo: 'SV0706', nombre: 'EL CARMEN', nombrePadre: 'LA UNION' },
	SV0707: { codigo: 'SV0707', nombre: 'CONCHAGUA', nombrePadre: 'LA UNION' },
	SV0708: { codigo: 'SV0708', nombre: 'EL SAUCE', nombrePadre: 'LA UNION' },
	SV0709: { codigo: 'SV0709', nombre: 'LISLIQUE', nombrePadre: 'LA UNION' },
	SV0710: { codigo: 'SV0710', nombre: 'YUCUAIQUIN', nombrePadre: 'LA UNION' },
	SV0711: { codigo: 'SV0711', nombre: 'NUEVA ESPARTA', nombrePadre: 'LA UNION' },
	SV0712: { codigo: 'SV0712', nombre: 'POLOROS', nombrePadre: 'LA UNION' },
	SV0713: { codigo: 'SV0713', nombre: 'BOLIVAR', nombrePadre: 'LA UNION' },
	SV0714: { codigo: 'SV0714', nombre: 'CONCEPCION DE ORIENTE', nombrePadre: 'LA UNION' },
	SV0715: { codigo: 'SV0715', nombre: 'INTIPUCA', nombrePadre: 'LA UNION' },
	SV0716: { codigo: 'SV0716', nombre: 'SAN JOSE LAS FUENTES', nombrePadre: 'LA UNION' },
	SV0717: { codigo: 'SV0717', nombre: 'YAYANTIQUE', nombrePadre: 'LA UNION' },
	SV0718: { codigo: 'SV0718', nombre: 'MEANGUERA DEL GOLFO', nombrePadre: 'LA UNION' },
	SV0801: { codigo: 'SV0801', nombre: 'ZACATECOLUCA', nombrePadre: 'LA PAZ' },
	SV0802: { codigo: 'SV0802', nombre: 'SANTIAGO NONUALCO', nombrePadre: 'LA PAZ' },
	SV0803: { codigo: 'SV0803', nombre: 'SAN JUAN NONUALCO', nombrePadre: 'LA PAZ' },
	SV0804: { codigo: 'SV0804', nombre: 'SAN PEDRO MASAHUAT', nombrePadre: 'LA PAZ' },
	SV0805: { codigo: 'SV0805', nombre: 'OLOCUILTA', nombrePadre: 'LA PAZ' },
	SV0806: { codigo: 'SV0806', nombre: 'SAN PEDRO NONUALCO', nombrePadre: 'LA PAZ' },
	SV0807: { codigo: 'SV0807', nombre: 'SAN FRANCISCO CHINAMECA', nombrePadre: 'LA PAZ' },
	SV0808: { codigo: 'SV0808', nombre: 'SAN JUAN TALPA', nombrePadre: 'LA PAZ' },
	SV0809: { codigo: 'SV0809', nombre: 'EL ROSARIO', nombrePadre: 'LA PAZ' },
	SV0810: { codigo: 'SV0810', nombre: 'SAN RAFAEL OBRAJUELO', nombrePadre: 'LA PAZ' },
	SV0811: { codigo: 'SV0811', nombre: 'SANTA MARIA OSTUMA', nombrePadre: 'LA PAZ' },
	SV0812: { codigo: 'SV0812', nombre: 'SAN LUIS TALPA', nombrePadre: 'LA PAZ' },
	SV0813: { codigo: 'SV0813', nombre: 'SAN ANTONIO MASAHUAT', nombrePadre: 'LA PAZ' },
	SV0814: { codigo: 'SV0814', nombre: 'SAN MIGUEL TEPEZONTES', nombrePadre: 'LA PAZ' },
	SV0815: { codigo: 'SV0815', nombre: 'SAN JUAN TEPEZONTES', nombrePadre: 'LA PAZ' },
	SV0816: { codigo: 'SV0816', nombre: 'TAPALHUACA', nombrePadre: 'LA PAZ' },
	SV0817: { codigo: 'SV0817', nombre: 'CUYULTITAN', nombrePadre: 'LA PAZ' },
	SV0818: { codigo: 'SV0818', nombre: 'PARAISO DE OSORIO', nombrePadre: 'LA PAZ' },
	SV0819: { codigo: 'SV0819', nombre: 'SAN EMIGDIO', nombrePadre: 'LA PAZ' },
	SV0820: { codigo: 'SV0820', nombre: 'JERUSALEN', nombrePadre: 'LA PAZ' },
	SV0821: { codigo: 'SV0821', nombre: 'MERCEDES LA CEIBA', nombrePadre: 'LA PAZ' },
	SV0822: { codigo: 'SV0822', nombre: 'SAN LUIS LA HERRADURA', nombrePadre: 'LA PAZ' },
	SV0901: { codigo: 'SV0901', nombre: 'CHALATENANGO', nombrePadre: 'CHALATENANGO' },
	SV0902: { codigo: 'SV0902', nombre: 'NUEVA CONCEPCION', nombrePadre: 'CHALATENANGO' },
	SV0903: { codigo: 'SV0903', nombre: 'LA PALMA', nombrePadre: 'CHALATENANGO' },
	SV0904: { codigo: 'SV0904', nombre: 'TEJUTLA', nombrePadre: 'CHALATENANGO' },
	SV0905: { codigo: 'SV0905', nombre: 'LA REINA', nombrePadre: 'CHALATENANGO' },
	SV0906: { codigo: 'SV0906', nombre: 'ARCATAO', nombrePadre: 'CHALATENANGO' },
	SV0907: { codigo: 'SV0907', nombre: 'SAN IGNACIO', nombrePadre: 'CHALATENANGO' },
	SV0908: { codigo: 'SV0908', nombre: 'DULCE NOMBRE DE MARIA', nombrePadre: 'CHALATENANGO' },
	SV0909: { codigo: 'SV0909', nombre: 'CITALA', nombrePadre: 'CHALATENANGO' },
	SV0910: { codigo: 'SV0910', nombre: 'AGUA CALIENTE', nombrePadre: 'CHALATENANGO' },
	SV0911: { codigo: 'SV0911', nombre: 'CONCEPCION QUEZALTEPEQUE', nombrePadre: 'CHALATENANGO' },
	SV0912: { codigo: 'SV0912', nombre: 'NUEVA TRINIDAD', nombrePadre: 'CHALATENANGO' },
	SV0913: { codigo: 'SV0913', nombre: 'LAS VUELTAS', nombrePadre: 'CHALATENANGO' },
	SV0914: { codigo: 'SV0914', nombre: 'COMALAPA', nombrePadre: 'CHALATENANGO' },
	SV0915: { codigo: 'SV0915', nombre: 'SAN RAFAEL', nombrePadre: 'CHALATENANGO' },
	SV0916: { codigo: 'SV0916', nombre: 'LAS FLORES', nombrePadre: 'CHALATENANGO' },
	SV0917: { codigo: 'SV0917', nombre: 'OJOS DE AGUA', nombrePadre: 'CHALATENANGO' },
	SV0918: { codigo: 'SV0918', nombre: 'NOMBRE DE JESUS', nombrePadre: 'CHALATENANGO' },
	SV0919: { codigo: 'SV0919', nombre: 'POTONICO', nombrePadre: 'CHALATENANGO' },
	SV0920: { codigo: 'SV0920', nombre: 'SAN FRANCISCO MORAZAN', nombrePadre: 'CHALATENANGO' },
	SV0921: { codigo: 'SV0921', nombre: 'SANTA RITA', nombrePadre: 'CHALATENANGO' },
	SV0922: { codigo: 'SV0922', nombre: 'LA LAGUNA', nombrePadre: 'CHALATENANGO' },
	SV0923: { codigo: 'SV0923', nombre: 'SAN ISIDRO LABRADOR', nombrePadre: 'CHALATENANGO' },
	SV0924: { codigo: 'SV0924', nombre: 'SAN ANTONIO DE LA CRUZ', nombrePadre: 'CHALATENANGO' },
	SV0925: { codigo: 'SV0925', nombre: 'EL PARAISO', nombrePadre: 'CHALATENANGO' },
	SV0926: { codigo: 'SV0926', nombre: 'SAN MIGUEL DE MERCEDES', nombrePadre: 'CHALATENANGO' },
	SV0927: { codigo: 'SV0927', nombre: 'SAN LUIS DEL CARMEN', nombrePadre: 'CHALATENANGO' },
	SV0928: { codigo: 'SV0928', nombre: 'SAN JOSE CANCASQUE', nombrePadre: 'CHALATENANGO' },
	SV0929: { codigo: 'SV0929', nombre: 'SAN ANTONIO LOS RANCHOS', nombrePadre: 'CHALATENANGO' },
	SV0930: { codigo: 'SV0930', nombre: 'EL CARRIZAL', nombrePadre: 'CHALATENANGO' },
	SV0931: { codigo: 'SV0931', nombre: 'SAN FERNANDO', nombrePadre: 'CHALATENANGO' },
	SV0932: { codigo: 'SV0932', nombre: 'AZACUALPA', nombrePadre: 'CHALATENANGO' },
	SV0933: { codigo: 'SV0933', nombre: 'SAN FRANCISCO LEMPA', nombrePadre: 'CHALATENANGO' },
	SV1001: { codigo: 'SV1001', nombre: 'COJUTEPEQUE', nombrePadre: 'CUSCATLAN' },
	SV1002: { codigo: 'SV1002', nombre: 'SUCHITOTO', nombrePadre: 'CUSCATLAN' },
	SV1003: { codigo: 'SV1003', nombre: 'SAN PEDRO PERULAPAN', nombrePadre: 'CUSCATLAN' },
	SV1004: { codigo: 'SV1004', nombre: 'SAN JOSE GUAYABAL', nombrePadre: 'CUSCATLAN' },
	SV1005: { codigo: 'SV1005', nombre: 'TENANCINGO', nombrePadre: 'CUSCATLAN' },
	SV1006: { codigo: 'SV1006', nombre: 'SAN RAFAEL CEDROS', nombrePadre: 'CUSCATLAN' },
	SV1007: { codigo: 'SV1007', nombre: 'CANDELARIA', nombrePadre: 'CUSCATLAN' },
	SV1008: { codigo: 'SV1008', nombre: 'EL CARMEN', nombrePadre: 'CUSCATLAN' },
	SV1009: { codigo: 'SV1009', nombre: 'MONTE SAN JUAN', nombrePadre: 'CUSCATLAN' },
	SV1010: { codigo: 'SV1010', nombre: 'SAN CRISTOBAL', nombrePadre: 'CUSCATLAN' },
	SV1011: { codigo: 'SV1011', nombre: 'SANTA CRUZ MICHAPA', nombrePadre: 'CUSCATLAN' },
	SV1012: { codigo: 'SV1012', nombre: 'SAN BARTOLOME PERULAPIA', nombrePadre: 'CUSCATLAN' },
	SV1013: { codigo: 'SV1013', nombre: 'SAN RAMON', nombrePadre: 'CUSCATLAN' },
	SV1014: { codigo: 'SV1014', nombre: 'EL ROSARIO', nombrePadre: 'CUSCATLAN' },
	SV1015: { codigo: 'SV1015', nombre: 'ORATORIO DE CONCEPCION', nombrePadre: 'CUSCATLAN' },
	SV1016: { codigo: 'SV1016', nombre: 'SANTA CRUZ ANALQUITO', nombrePadre: 'CUSCATLAN' },
	SV1101: { codigo: 'SV1101', nombre: 'AHUACHAPAN', nombrePadre: 'AHUACHAPAN' },
	SV1102: { codigo: 'SV1102', nombre: 'ATIQUIZAYA', nombrePadre: 'AHUACHAPAN' },
	SV1103: { codigo: 'SV1103', nombre: 'SAN FRANCISCO MENENDEZ', nombrePadre: 'AHUACHAPAN' },
	SV1104: { codigo: 'SV1104', nombre: 'TACUBA', nombrePadre: 'AHUACHAPAN' },
	SV1105: { codigo: 'SV1105', nombre: 'CONCEPCION DE ATACO', nombrePadre: 'AHUACHAPAN' },
	SV1106: { codigo: 'SV1106', nombre: 'JUJUTLA', nombrePadre: 'AHUACHAPAN' },
	SV1107: { codigo: 'SV1107', nombre: 'GUAYMANGO', nombrePadre: 'AHUACHAPAN' },
	SV1108: { codigo: 'SV1108', nombre: 'APANECA', nombrePadre: 'AHUACHAPAN' },
	SV1109: { codigo: 'SV1109', nombre: 'SAN PEDRO PUXTLA', nombrePadre: 'AHUACHAPAN' },
	SV1110: { codigo: 'SV1110', nombre: 'SAN LORENZO', nombrePadre: 'AHUACHAPAN' },
	SV1111: { codigo: 'SV1111', nombre: 'TURIN', nombrePadre: 'AHUACHAPAN' },
	SV1112: { codigo: 'SV1112', nombre: 'EL REFUGIO', nombrePadre: 'AHUACHAPAN' },
	SV1201: { codigo: 'SV1201', nombre: 'SAN FRANCISCO GOTERA', nombrePadre: 'MORAZAN' },
	SV1202: { codigo: 'SV1202', nombre: 'JOCORO', nombrePadre: 'MORAZAN' },
	SV1203: { codigo: 'SV1203', nombre: 'CORINTO', nombrePadre: 'MORAZAN' },
	SV1204: { codigo: 'SV1204', nombre: 'SOCIEDAD', nombrePadre: 'MORAZAN' },
	SV1205: { codigo: 'SV1205', nombre: 'CACAOPERA', nombrePadre: 'MORAZAN' },
	SV1206: { codigo: 'SV1206', nombre: 'GUATAJIAGUA', nombrePadre: 'MORAZAN' },
	SV1207: { codigo: 'SV1207', nombre: 'EL DIVISADERO', nombrePadre: 'MORAZAN' },
	SV1208: { codigo: 'SV1208', nombre: 'JOCOAITIQUE', nombrePadre: 'MORAZAN' },
	SV1209: { codigo: 'SV1209', nombre: 'OSICALA', nombrePadre: 'MORAZAN' },
	SV1210: { codigo: 'SV1210', nombre: 'CHILANGA', nombrePadre: 'MORAZAN' },
	SV1211: { codigo: 'SV1211', nombre: 'MEANGUERA', nombrePadre: 'MORAZAN' },
	SV1212: { codigo: 'SV1212', nombre: 'TOROLA', nombrePadre: 'MORAZAN' },
	SV1213: { codigo: 'SV1213', nombre: 'SAN SIMON', nombrePadre: 'MORAZAN' },
	SV1214: { codigo: 'SV1214', nombre: 'DELICIAS DE CONCEPCION', nombrePadre: 'MORAZAN' },
	SV1215: { codigo: 'SV1215', nombre: 'JOATECA', nombrePadre: 'MORAZAN' },
	SV1216: { codigo: 'SV1216', nombre: 'ARAMBALA', nombrePadre: 'MORAZAN' },
	SV1217: { codigo: 'SV1217', nombre: 'LOLOTIQUILLO', nombrePadre: 'MORAZAN' },
	SV1218: { codigo: 'SV1218', nombre: 'YAMABAL', nombrePadre: 'MORAZAN' },
	SV1219: { codigo: 'SV1219', nombre: 'YOLOAIQUIN', nombrePadre: 'MORAZAN' },
	SV1220: { codigo: 'SV1220', nombre: 'SAN CARLOS', nombrePadre: 'MORAZAN' },
	SV1221: { codigo: 'SV1221', nombre: 'EL ROSARIO', nombrePadre: 'MORAZAN' },
	SV1222: { codigo: 'SV1222', nombre: 'PERQUIN', nombrePadre: 'MORAZAN' },
	SV1223: { codigo: 'SV1223', nombre: 'SENSEMBRA', nombrePadre: 'MORAZAN' },
	SV1224: { codigo: 'SV1224', nombre: 'GUALOCOCTI', nombrePadre: 'MORAZAN' },
	SV1225: { codigo: 'SV1225', nombre: 'SAN FERNANDO', nombrePadre: 'MORAZAN' },
	SV1226: { codigo: 'SV1226', nombre: 'SAN ISIDRO', nombrePadre: 'MORAZAN' },
	SV1301: { codigo: 'SV1301', nombre: 'SAN VICENTE', nombrePadre: 'SAN VICENTE' },
	SV1302: { codigo: 'SV1302', nombre: 'TECOLUCA', nombrePadre: 'SAN VICENTE' },
	SV1303: { codigo: 'SV1303', nombre: 'SAN SEBASTIAN', nombrePadre: 'SAN VICENTE' },
	SV1304: { codigo: 'SV1304', nombre: 'APASTEPEQUE', nombrePadre: 'SAN VICENTE' },
	SV1305: { codigo: 'SV1305', nombre: 'SAN ESTEBAN CATARINA', nombrePadre: 'SAN VICENTE' },
	SV1306: { codigo: 'SV1306', nombre: 'SAN ILDEFONSO', nombrePadre: 'SAN VICENTE' },
	SV1307: { codigo: 'SV1307', nombre: 'SANTA CLARA', nombrePadre: 'SAN VICENTE' },
	SV1308: { codigo: 'SV1308', nombre: 'SAN LORENZO', nombrePadre: 'SAN VICENTE' },
	SV1309: { codigo: 'SV1309', nombre: 'VERAPAZ', nombrePadre: 'SAN VICENTE' },
	SV1310: { codigo: 'SV1310', nombre: 'GUADALUPE', nombrePadre: 'SAN VICENTE' },
	SV1311: { codigo: 'SV1311', nombre: 'SANTO DOMINGO', nombrePadre: 'SAN VICENTE' },
	SV1312: { codigo: 'SV1312', nombre: 'SAN CAYETANO ISTEPEQUE', nombrePadre: 'SAN VICENTE' },
	SV1313: { codigo: 'SV1313', nombre: 'TEPETITAN', nombrePadre: 'SAN VICENTE' },
	SV1401: { codigo: 'SV1401', nombre: 'SENSUNTEPEQUE', nombrePadre: 'CABAÑAS' },
	SV1402: { codigo: 'SV1402', nombre: 'ILOBASCO', nombrePadre: 'CABAÑAS' },
	SV1403: { codigo: 'SV1403', nombre: 'VICTORIA', nombrePadre: 'CABAÑAS' },
	SV1404: { codigo: 'SV1404', nombre: 'SAN ISIDRO', nombrePadre: 'CABAÑAS' },
	SV1405: { codigo: 'SV1405', nombre: 'JUTIAPA', nombrePadre: 'CABAÑAS' },
	SV1406: { codigo: 'SV1406', nombre: 'TEJUTEPEQUE', nombrePadre: 'CABAÑAS' },
	SV1407: { codigo: 'SV1407', nombre: 'DOLORES', nombrePadre: 'CABAÑAS' },
	SV1408: { codigo: 'SV1408', nombre: 'CINQUERA', nombrePadre: 'CABAÑAS' },
	SV1409: { codigo: 'SV1409', nombre: 'GUACOTECTI', nombrePadre: 'CABAÑAS' },
};

const mapa: Record<string, string[]> = {
	SV:   [ 'SV01', 'SV02', 'SV03', 'SV04', 'SV05', 'SV06', 'SV07', 'SV08', 'SV09', 'SV10', 'SV11', 'SV12', 'SV13', 'SV14' ],
	SV01: [ 'SV0101', 'SV0102', 'SV0103', 'SV0104', 'SV0105', 'SV0106', 'SV0107', 'SV0108', 'SV0109', 'SV0110', 'SV0111', 'SV0112', 'SV0113', 'SV0114', 'SV0115', 'SV0116', 'SV0117', 'SV0118', 'SV0119' ],
	SV02: [ 'SV0201', 'SV0202', 'SV0203', 'SV0204', 'SV0205', 'SV0206', 'SV0207', 'SV0208', 'SV0209', 'SV0210', 'SV0211', 'SV0212', 'SV0213' ],
	SV03: [ 'SV0301', 'SV0302', 'SV0303', 'SV0304', 'SV0305', 'SV0306', 'SV0307', 'SV0308', 'SV0309', 'SV0310', 'SV0311', 'SV0312', 'SV0313', 'SV0314', 'SV0315', 'SV0316', 'SV0317', 'SV0318', 'SV0319', 'SV0320' ],
	SV04: [ 'SV0401', 'SV0402', 'SV0403', 'SV0404', 'SV0405', 'SV0406', 'SV0407', 'SV0408', 'SV0409', 'SV0410', 'SV0411', 'SV0412', 'SV0413', 'SV0414', 'SV0415', 'SV0416', 'SV0417', 'SV0418', 'SV0419', 'SV0420', 'SV0421', 'SV0422' ],
	SV05: [ 'SV0501', 'SV0502', 'SV0503', 'SV0504', 'SV0505', 'SV0506', 'SV0507', 'SV0508', 'SV0509', 'SV0510', 'SV0511', 'SV0512', 'SV0513', 'SV0514', 'SV0515', 'SV0516', 'SV0517', 'SV0518', 'SV0519', 'SV0520', 'SV0521', 'SV0522', 'SV0523' ],
	SV06: [ 'SV0601', 'SV0602', 'SV0603', 'SV0604', 'SV0605', 'SV0606', 'SV0607', 'SV0608', 'SV0609', 'SV0610', 'SV0611', 'SV0612', 'SV0613', 'SV0614', 'SV0615', 'SV0616' ],
	SV07: [ 'SV0701', 'SV0702', 'SV0703', 'SV0704', 'SV0705', 'SV0706', 'SV0707', 'SV0708', 'SV0709', 'SV0710', 'SV0711', 'SV0712', 'SV0713', 'SV0714', 'SV0715', 'SV0716', 'SV0717', 'SV0718' ],
	SV08: [ 'SV0801', 'SV0802', 'SV0803', 'SV0804', 'SV0805', 'SV0806', 'SV0807', 'SV0808', 'SV0809', 'SV0810', 'SV0811', 'SV0812', 'SV0813', 'SV0814', 'SV0815', 'SV0816', 'SV0817', 'SV0818', 'SV0819', 'SV0820', 'SV0821', 'SV0822' ],
	SV09: [ 'SV0901', 'SV0902', 'SV0903', 'SV0904', 'SV0905', 'SV0906', 'SV0907', 'SV0908', 'SV0909', 'SV0910', 'SV0911', 'SV0912', 'SV0913', 'SV0914', 'SV0915', 'SV0916', 'SV0917', 'SV0918', 'SV0919', 'SV0920', 'SV0921', 'SV0922', 'SV0923', 'SV0924', 'SV0925', 'SV0926', 'SV0927', 'SV0928', 'SV0929', 'SV0930', 'SV0931', 'SV0932', 'SV0933' ],
	SV10: [ 'SV1001', 'SV1002', 'SV1003', 'SV1004', 'SV1005', 'SV1006', 'SV1007', 'SV1008', 'SV1009', 'SV1010', 'SV1011', 'SV1012', 'SV1013', 'SV1014', 'SV1015', 'SV1016' ],
	SV11: [ 'SV1101', 'SV1102', 'SV1103', 'SV1104', 'SV1105', 'SV1106', 'SV1107', 'SV1108', 'SV1109', 'SV1110', 'SV1111', 'SV1112' ],
	SV12: [ 'SV1201', 'SV1202', 'SV1203', 'SV1204', 'SV1205', 'SV1206', 'SV1207', 'SV1208', 'SV1209', 'SV1210', 'SV1211', 'SV1212', 'SV1213', 'SV1214', 'SV1215', 'SV1216', 'SV1217', 'SV1218', 'SV1219', 'SV1220', 'SV1221', 'SV1222', 'SV1223', 'SV1224', 'SV1225', 'SV1226' ],
	SV13: [ 'SV1301', 'SV1302', 'SV1303', 'SV1304', 'SV1305', 'SV1306', 'SV1307', 'SV1308', 'SV1309', 'SV1310', 'SV1311', 'SV1312', 'SV1313' ],
	SV14: [ 'SV1401', 'SV1402', 'SV1403', 'SV1404', 'SV1405', 'SV1406', 'SV1407', 'SV1408', 'SV1409' ],
};

const territorios = {
	SV: { codigo: 'SV', nombre: 'EL SALVADOR' },
	...departamentos,
	...municipios
}

const cyanRegions = {
	departamentos,
	municipios,
	mapa,
	territorios
}

export { territorios, departamentos, municipios, mapa };
export default cyanRegions;

