<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-app :data-bocaUrna="esAzul ? 1 : 0">
    <ion-menu contentId="main-content" side="end">
      <ion-toolbar>
        <ion-title>Menú</ion-title>
      </ion-toolbar>

      <ion-content>
        <ion-list lines="none">
          <ion-item @click="menuGo('/home')">
            <ion-icon slot="start" :icon="homeOutline"></ion-icon>
            Inicio
          </ion-item>
          <ion-item
            @click="menuGo('/partyLevels')"
            v-if="$store.getters.userHasPermission('estructura')"
          >
            <ion-icon
              slot="start"
              src="assets/images/Icono-N-Gris.svg"
            ></ion-icon>
            Partido</ion-item
          >
          <ion-item
            @click="menuGo('/voters')"
            v-if="$store.getters.userHasPermission('estructura')"
          >
            <ion-icon
              slot="start"
              src="assets/images/Icono-Manos-Gris-Menu.svg"
            ></ion-icon>
            Carga Electoral</ion-item
          >

          <ion-item
            @click="menuGo($store.getters.rutaDirectorio)"
            v-if="$store.getters.userHasPermission('directorio')"
          >
            <ion-icon slot="start" :icon="helpBuoyOutline"></ion-icon>
            Directorio</ion-item
          >

          <ion-item
            @click="menuGo($store.getters.rutaPuntoCyan)"
            v-if="$store.getters.userHasPermission('punto')"
          >
            <ion-icon slot="start" :icon="megaphoneOutline"></ion-icon>
            Punto Cyan</ion-item
          >

          <ion-item
            @click="menuGo($store.getters.rutaAgente)"
            v-if="$store.getters.userHasPermission('agente')"
          >
            <ion-icon slot="start" :icon="trophyOutline"></ion-icon>
            Control Electoral</ion-item
          >

          <ion-item @click="menuLogout" lines="none">
            Cerrar Sesión
            <ion-icon slot="start" :icon="exitOutline"></ion-icon>
          </ion-item>
        </ion-list>
        <user-data class="mobileShowFlex sideMenu" />
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content" :animated="true"></ion-router-outlet>
    <vue-easy-lightbox
      :imgs="lightboxImages"
      ref="lightbox"
      :visible="lightboxVisible"
      @hide="esconderLightbox"
    ></vue-easy-lightbox>
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonContent,
  IonTitle,
  IonToolbar,
  IonList,
  IonIcon,
  IonMenu,
  IonItem,
  menuController,
} from "@ionic/vue";

import {
  arrowBack,
  chevronBack,
  homeOutline,
  exitOutline,
  helpBuoyOutline,
  trophyOutline,
  megaphoneOutline,
} from "ionicons/icons";
import { defineComponent } from "vue";
import router from "@/router";
import store from "@/modules/adaptedStorage";
import UserData from "@/components/UserData.vue";
import logout from "@/modules/cyanLogout";

const AppComponent = defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonIcon,
    IonList,
    IonTitle,
    IonToolbar,
    IonMenu,
    UserData,
    IonItem,
  },
  setup() {
    return {
      arrowBack,
      chevronBack,
      homeOutline,
      exitOutline,
      helpBuoyOutline,
      trophyOutline,
      megaphoneOutline,
    };
  },
  computed: {
    esAzul() {
      return store.getters.userHasPermission('busl') && !store.getters.userHasPermission('jrsl') && !store.getters.userHasPermission('pcsl');
    },
    lightboxVisible() {
      return store.state.lightboxVisible;
    },
    lightboxImages() {
      return store.state.lightboxImages;
    },
  },
  methods: {
    menuGo(where: any) {
      menuController.close();
      router.push(where);
    },
    esconderLightbox() {
      store.commit("setLightboxVisible", false);
    },
    menuLogout() {
      menuController.close();
      logout();
    },
  },
});

export default AppComponent;
</script>

<style scoped>
ion-content,
ion-toolbar {
  --background: var(--ion-color-tertiary);
}
ion-menu ion-toolbar,
ion-header ion-toolbar {
  --background: var(--ion-color-primary);
  color: white;
}

[data-bocaurna="1"] ion-menu ion-toolbar,
[data-bocaurna="1"] ion-header ion-toolbar {
  --background: var(--azul-alternativo);
}

ion-menu ion-toolbar {
  padding-top: var(--ion-safe-area-top);
}
ion-menu ion-content {
  padding-bottom: var(--ion-safe-area-bottom);
}

ion-router-outlet {
  padding: 5px 5px 20px;
}

ion-router-outlet.isForm {
  padding: 0px;
}

ion-menu ion-item {
  cursor: pointer;
}

ion-menu ion-content,
ion-menu ion-item,
ion-menu ion-list {
  --background: var(--cyan-lighter-background);
  background: var(--cyan-lighter-background);
  --color: var(--cyan-dark-grey);
}

[data-bocaurna="1"] ion-menu ion-content,
[data-bocaurna="1"] ion-menu ion-item,
[data-bocaurna="1"] ion-menu ion-list {
  --background: var(--azul-alternativo-light);
  background: var(--azul-alternativo-light);
}


ion-menu ion-icon {
  --webkit-margin-end: 12px;
  margin-inline-end: 12px;
  margin-right: 12px;
}

@media (min-width: 768px) and (min-height: 500px) {
  .header-md::after {
    display: none;
  }
  ion-router-outlet {
    padding: 0 110px;
  }
  ion-router-outlet.burst {
    padding: 0 20px 40px;
  }
}
</style>